body {
  background: #313d2c;
  color: #ccc;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Slab', serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, svg, hr {
	-webkit-transform:  translateZ(0);
	-webkit-transition: all 0s linear;
	-moz-transition:    all 0s linear;
	-ms-transition:     all 0s linear;
	-o-transition:      all 0s linear;
	transition:         all 0s linear;
}

* {
    margin: 0;
    padding: 0;
}

.illustration .image {
    position:               absolute;
    height:                 100vh;
    width:                  100%;
    background-Repeat:      no-repeat;
    background-Position:    top center;
    background-Size:        cover;
}

.illustration {
    height:     100vh; 
    display:    flex;
    overflow:   hidden;
    position:   relative;
}

div.introImage-0 {
    border-bottom: 5vh #313d2c solid;
}

#about {
    background:         #313d2c;
    min-height:         500px;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
}

#about > div {
    width: 100%;
}

#about > div hr {
    margin-top: 15px;
}

#about img {
    border-radius: 100px;
}

#about #mePicture {
  border-radius: 150px;
  margin: 0 5px;
  border: 3px solid orange;
  width: 7rem;
}

#about a {
  color: #fff;
  text-decoration: none;
}

#about .fa-stack {
  font-size: 3rem;
  vertical-align: super;
}

#about .fa-inverse {
  color: #3fe5b3 !important;
}

#about h2 {
  color: orange;
  font-size: 4em;
  margin: 25px;
  position: relative;
}

#about hr {
  border: 1px solid orange;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}

#about p {
  padding: 25px 25px 0;
  max-width: 500px;
  margin: 0 auto;
}

.skills {
    width: 100%;
    margin-bottom: 25px;
    margin-top: -100px;
    position: relative;
    z-index: 2;
}

.skills h2, #portfolio h2 {
    color: #9bce53;
    font-size: 4em;
    margin-bottom: 0;
}

.skills > div {
    display:            flex;
    width:              600px;
    align-items:        center;
    align-content:      center;
    margin:             0 auto;
    justify-content:    center;
    justify-items:      center;
    margin-top:         25px;
}

.skills div img {
    height: 300px;
    width: 300px;
}

.skills > div:nth-child(even) {
    flex-direction: row-reverse;
}

.skills h3 {
    font-size: 1.8em;
    padding: 15px 0;
}

.skills p {
    padding: 0;
    font-size: 1.1em;
    text-align: left;
    line-height: 2em;
}

#portfolio {
    overflow: hidden;
}

#portfolio > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
    text-align: left;
    margin-left: -15vw;
}

#portfolio > div > div:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-right: -15vw;
    margin-left: 0;
}

@media screen and (max-width:1270px) {
    #portfolio > div > div, #portfolio > div > div:nth-child(2n) {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    #portfolio > div > div > div:nth-child(2) {
        text-align: center;
    }
}

#portfolio > div > div > div {
    width: 600px;
    font-size: 1.5em;
}

#portfolio > div > div > div h3 {
    font-size: 2em;
}

#portfolio > div > div > div p {
    padding: 25px 0;
}

#portfolio > div > div > div a {
    text-decoration: none;
}

#portfolio > div > div > div a:hover {
    text-decoration: underline;
    color: rgb(155, 206, 83);
    cursor: pointer;
}

#portfolio > div > div > div button {
    background: none;
    padding: 20px;
    display: block;
    width: 100%;
    border: 3px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2em;
    margin: 15px auto;
}

#portfolio > div > div > div button:hover, #portfolio > div > div > div a:hover {
    color: rgb(155, 206, 83);
    cursor: pointer;
    text-decoration: none;
}

#portfolio > div > div:nth-child(even) > div:nth-child(2) {
    margin-right: 20px;
}

#portfolio > div > div > div:nth-child(2) {
    width: 375px;
    padding: 25px;
    margin-left: 20px;
}

#portfolio img {
    max-width: 600px;
    margin: 0 auto;
    border: 3px solid #fff;
    border-radius: 10px 10px 0 0;
}

@media screen and (max-width:400px) {
    #portfolio img {
        width: 280px;
    }

    #portfolio > div > div > div, #portfolio > div > div > div button {
        width: auto;
    }
}

#contact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.2em;
    color: #fff;
}

#contact h2 {
    color: rgb(240, 90, 96);
    font-size: 4em;
    margin: 25px;
    position: relative;
}

#contact hr {
    max-width: 335px; 
    border-color: rgb(240, 90, 96); 
    transform: scale(150, .5);
}

#contact .myEmail {
    font-size: 2em;
}

@media only screen and (max-width: 600px) {
    #contact h2 {
        font-size: 2em;
    }

    #contact .myEmail {
        font-size: 1.5em;
    }

    .skills > div {
        width: 100%;
        flex-direction: column !important;
    }

    .skills > img {
        width: 100%;
    }

    .skills div > p {
        padding: 15px 25px 0;
    }

    #skill-creative img {
        padding-left: 80px;
    }

    #about p {
        padding: 50px 25px 0;
    }
}